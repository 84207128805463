import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Switch, Route } from 'react-router';
import * as Sentry from '@sentry/react';

import { PATHS } from '@team-seenit/constants';
import { AppProvider, useAuth } from '@team-seenit/contexts';
import {
  LoginPage,
  LoginCallbackPage,
  LogoutPage,
  LogoutCallbackPage,
  VideoNotFoundPage,
  VideoPlayerPage,
} from '@team-seenit/pages';
import { createApolloClient } from './config';

const App = () => {
  const { accessToken, loading } = useAuth();

  if (loading) return '';

  const apolloClient = createApolloClient({ logger: console, accessToken });

  return (
    <AppProvider>
      <ApolloProvider client={apolloClient}>
        <Switch>
          <Route path={PATHS.LOGOUT}>
            <LogoutPage />
          </Route>

          <Route path={PATHS.LOGIN}>
            <LoginPage />
          </Route>

          <Route exact path={PATHS.LOGIN_CALLBACK}>
            <LoginCallbackPage />
          </Route>

          <Route exact path={PATHS.LOGOUT_CALLBACK}>
            <LogoutCallbackPage />
          </Route>

          <Route path="/watch/:shareCode">
            <VideoPlayerPage />
          </Route>

          <Route path="*">
            <VideoNotFoundPage />
          </Route>
        </Switch>
      </ApolloProvider>
    </AppProvider>
  );
};

export default Sentry.withProfiler(App);
