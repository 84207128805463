import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { typePolicies } from '@team-seenit/contexts';

import createSessionLink from './links/sessionLink';
import configCreator from './envConfig';

const config = configCreator();

const cache = new InMemoryCache({ typePolicies });
const clientOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const createErrorLink = logger => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        logger.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) logger.log(`[Network error]: ${networkError}`);
  });
};

const httpLink = createHttpLink({
  uri: config.graphqlEndpoint,
  headers: { apikey: config.graphqlApiKey },
});

const authLink = authorization =>
  setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return { headers: { ...headers, authorization } };
  });

const createApolloClient = ({
  accessToken,
  logger,
  defaultOptions = clientOptions,
}) => {
  const link = ApolloLink.from([
    createSessionLink(null, config),
    createErrorLink(logger),
    new SentryLink({
      uri: config.graphqlEndpoint,
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true,
        includeFetchResult: true,
      },
    }),
    authLink(accessToken),
    httpLink,
  ]);

  const client = new ApolloClient({
    cache,
    link,
    defaultOptions,
    connectToDevTools: config.connectToDevTools,
    queryDeduplication: false,
  });

  return client;
};

export default createApolloClient;
