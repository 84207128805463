import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { capitalize } from 'lodash';
import ReactDOM from 'react-dom';

import {
  AnalyticsProvider,
  AuthProvider,
  defaultTheme,
  ThemeProvider,
  CloudinaryProvider,
} from '@team-seenit/contexts';
import { PATHS } from '@team-seenit/constants';

import { contentfulSetup, envConfig } from './config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './bundle.css';

const config = envConfig();

const history = createBrowserHistory();

Sentry.init({
  allowUrls: [
    /https:\/\/(app.seenit.io|tv-(master|demo|staging).seenit.studio|stellar-tv.eu.ngrok.io)/,
  ],
  dsn: config.sentry.dsn,
  environment: capitalize(config.sentry.environment),
  integrations: [
    new BrowserTracing({
      tracingOrigins: [config.graphqlEndpoint],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

contentfulSetup(config.contentfulConfig);

// const container = document.getElementById('root');
// const root = createRoot(container);

ReactDOM.hydrate(
  <Router history={history}>
    <AnalyticsProvider value={{ history }}>
      <AuthProvider
        domain={config.auth0Config.domain}
        clientId={config.auth0Config.clientId}
        audience={config.auth0Config.audience}
        redirectUri={`${window.location.origin}${PATHS.LOGIN_CALLBACK}`}
        value={{ config }}
        cacheLocation={window.Cypress ? 'localstorage' : undefined}
      >
        <ThemeProvider theme={defaultTheme}>
          <CloudinaryProvider config={{ ...config.cloudinaryConfig }}>
            <App />
          </CloudinaryProvider>
        </ThemeProvider>
      </AuthProvider>
    </AnalyticsProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
