export default () => ({
  debug: process.env.REACT_APP_GRAPHQL_DEBUG,
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_SERVICE,
  graphqlApiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
  connectToDevTools: process.env.REACT_APP_CONNECTO_TO_DEV_TOOLS === 'true',
  contentfulConfig: {
    baseURL: process.env.REACT_APP_CONTENTFUL_BASE_URL,
    spaces: process.env.REACT_APP_CONTENTFUL_SPACES,
    accesstoken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    cacheMax: process.env.REACT_APP_CONTENTFUL_CACHE_MAX,
  },
  auth0Config: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  },
  baseUserServiceURL: process.env.REACT_APP_SERVICE_USERS_URL,
  cloudinaryConfig: {
    baseURL: process.env.REACT_APP_CLOUDINARY_BASE_URL,
    cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
    cname: process.env.REACT_APP_CLOUDINARY_CNAME,
    privateCDN: process.env.REACT_APP_CLOUDINARY_PRIVATE_CDN,
    orgAvatarUploadPreset:
      process.env.REACT_APP_CLOUDINARY_ORG_AVATAR_UPLOAD_PRESET,
    userAvatarUploadPreset:
      process.env.REACT_APP_CLOUDINARY_USER_AVATAR_UPLOAD_PRESET,
    authenticatedUploaderPreset:
      process.env.REACT_APP_CLOUDINARY_AUTHENTICATED_UPLOADER_PRESET,
    allowCrossOrigin: process.env.REACT_APP_CLOUDINARY_ALLOW_CROSS_ORIGIN,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
  talk: {
    appId: process.env.REACT_APP_TALKJS_APP_ID,
  },
  version: process.env.REACT_APP_VERSION,
  wevideo: {
    baseURL: process.env.REACT_APP_WEVIDEO_BASE_URL,
    interval: process.env.REACT_APP_WEVIDEO_INTERVAL,
    period: process.env.REACT_APP_WEVIDEO_PERIOD,
    modalDuration: process.env.REACT_APP_WEVIDEO_MODAL_DURATION,
  },
  templateBaseURL: process.env.REACT_APP_TEMPLATE_BASE_URL,
  fbAppId: process.env.REACT_APP_FB_APP_ID,
});
